const moduleJPLanguage = {
  HomePage: {
    toggleBtn: "English",
    Menu: {
      contactText: "お問い合わせ",
      homeText: "TOP",
      businessText: "事業案内",
      companyText: "会社情報",
      recruitment: "採用情報",
      workText: "制作事例",
      blogText: "ブログ",
      securityText: "情報セキュリティ方針",
      copyRight: "Copyright © 2023 SPS Corp Ltd."
    },
    Top: {
      subTitle: "ゲーム業界に高品質なデジタルアートコンテンツを提供します。",
      btnText: "SPSのメタバースを見る",
      btnHowToText: "メタバース操作方法"
    },
    Description: {
      sub1: `コンピュータグラフィックスの受託開発など中心に、
      日本の先進的ゲーム製作の専門法人を設立しました。
      キャラクター原画、背景原画、３Ｄキャラクター、３Ｄ背景、
      ゲームキャラクターのデザイン、モデリング、モーションなど作成します。
      人型のキャラクターだけではなく、モンスターのデザインも可能です。`,
      sub2: `機密保持契約の関係で現在の製作実績をホームページ上に掲載出来ません。
      興味がございましたらお問い合わせください。`,
    },
    News: {
      newsTitle: "更新情報",
      newsSubTitle: "バックナンバー",
    },
    footer: {
      title: "アクセス",
      subTitle: "SPS合同会社",
      address: "〒335-0004 埼玉県蕨市中央4丁目11-12アネックス蕨307号",
      roadMap: {
        fir: "1. JR京浜東北線　蕨駅下車",
        sec: "2. 西口ロータリーに出たら正面の道路を信号３つ、8分程度直進",
        third: "3. 道路左手に「つるや（青い看板）」の先の路地を左折、１分程度進む",
        fourth: "4. 左手にマンション「アネックス蕨（写真）」の３階に弊社があります",
        fifth: "5. 駐車場の中の扉からお入りください"
      },
    }
  },
  Product: {
    introduce: {
      first: "ＳＰＳ合同会社は、コンピュータグラフィックスの受託開発など中心に",
      sec: `日本の先進的ゲーム製作の専門法人を2019年3月に設立しました。`,
      third: `ＳＰＳ合同会社は、コンピュータグラフィックスの受託開発など中心に`,
      fourth: `日本の先進的ゲーム製作の専門法人を2019年3月に設立しました。`,
      fifth: `制作事例は、機密保持契約上、現在の製作実績をホームページに掲載出来ません。`,
      sixth: "ご興味がございましたら",
      seventh: "「お問い合わせ」",
      eighth: " にてご連絡下さいませ。"
    },
    content: {
      first: "メイン事業は：ゲーム業界に高品質なデジタルアートコンテンツを提供します。",
      sec: `キャラクター原画、背景原画、３Ｄキャラクター、３Ｄ背景などゲームに登場するキャラクターのデザイン、モデリング、モーションを作成します。人型のキャラクターだけではなく、モンスターのデザインも行います。`,
      third: `工業系・医療系・ゲーム向け等写真と見間違うクオリティで提供しています。`,
      fourth: `各制作工程単位での受託案件で高いクオリティを発揮することはもちろん世界中から存在価値を認められる事を目指すとともに、より多くの人々へ夢と感動を届け続けます。`,
    },
    photo: {
      first: "CG及び映像のプロデュース・ディレクション・制作",
      second: "業務用、ゲーム用等ソフトウェア開発",
      third: "有料人材紹介サービス",
      fourth: "翻訳・通訳（中国語・日本語・英語・韓国語）",
      fifth: "IDC事業（サーバー設置・配線工事/データセンター運用・保守等）",
    },
    footer: {
      name: "SPS合同会社",
      address: "〒335-0004 埼玉県蕨市中央4丁目11-12アネックス蕨307号",
    }
  },
  Company: {
    name: "会社名",
    nameContent: "SPS合同会社",
    address: "住所",
    addressContent: "〒335-0004　埼玉県蕨市中央4-11-12　アネックス蕨307",
    addressContent2: "4-11-12 annex warabi room no 307, warabi shi chuo, saitama",
    date: "設立",
    dateContent: "平成３１年３月８日",
    capital: "資本金",
    capitalContent: "950万円",
    bank: "取引銀行",
    bankContent: "三井住友銀行、川口信用金庫",
    Representative: "代表",
    RepresentativeContent: "萩原　昇（ハギワラ　ノボル）",
    Qualifications: "免許・登録",
    QualificationsContent: {
      first: "登録支援機関　23登ー009018",
      second: "SECUTIRY　ACTION(二つ星)宣言",
      third: "経営革新計画承認番号：産支　第1389号",
      fourth: "適格請求書発行事業者登録番号：T4030003013240",
    },
    Partners: "主な取引先",
    PartnersContent: "",
  },
  Recruitment: {
    title: "人材募集",
    form: {
      Profession: "専門",
      ProfessionContent: `MAYA /MAX/PHOTO SHOPのソフトを駆使してゲームの背景,キャラクターを書ける技術者`,
      Description: "業務",
      DescriptionContent: {
        first: `
        キャラクターのデザイン担当、・３Ｄ背景モデリング（Maya使用）・３Ｄキャラクターモデリング（Maya使用）・`,
        second: `テクスチャ制作、画像加工（Photoshop使用）・メーカー及び社内プランナーとの各種打ち合わせなど`,
        third: " ■使用OS：windows",
        fourth: " ■使用ソフト：Maya、Photoshop",
      },
      Type: "職種",
      TypeContent: "モデラー",
      Year: "業務経験",
      YearContent: "2～3年以上",
      Salary: "待遇",
      SalaryContent: "25万円〜",
      Employment: "雇用形態",
      EmploymentContent: "契約社員",
    },
    comment: {
      first: "採用情報についてご不明な点は、",
      second: "「お問い合わせ」",
      third: "からご連絡ください。",
    }
  },
  Work: {
    title: "制作事例",
    Bg: "２D背景",
    Character: "２Dキャラクター",
    Model: "３Dモデリング",
  },
  Security: {
    title: "セキュリティ方針",
    content: {
      detail: {
        first: "ＳＰＳ合同会社は（以下、当社）は、お客様からお預かりした情報及び当社の情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。",
        second: "当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。",
        third: "当社は、情報セキュリティの維持及び改善のために組織を設置し、情報セキュリティ対策を社内の正式な規則として定めます。当該組織は責任者は当社代表、組織長は当社業務執行役員、実務担当者で構成した体制で推進します。",
        fourth: "当社の従業員は、情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。",
        fifth: `当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。
            `,
        sixth: `当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。
            `,
      },
      title: {
        first: "1.経営者の責任",
        second: "2.社内体制の整備",
        third: "3.従業員の取組み",
        fourth: "4.法令及び契約上の要求事項の遵守",
        fifth: "5.違反及び事故への対応",
      }
    },
    foot: {
      date: "制定日:2020年6月24日",
      name: "ＳＰＳ合同会社",
      behalf: "代表社員　堀場　政夫"
    }
  },
  Contact: {
    name: "お名前",
    tips: {
      Required: "必須",
      InputTips: "お名前を入力してください。",
      errtip1: "2桁以上の文字を入力してください。",
      InputTips2: "メールアドレスを入力してください。",
      errtip2: "正しいメールアドレスを入力してください。",
      InputTips3: "電話番号を入力してください。",
      InputTips4: "題名を入力してください。",
      errtip4: "2桁以上の文字を入力してください。",
      InputTips5: "お問い合わせの詳細を入力してください。",
    },
    address: "メールアドレス",
    phone: "電話番号",
    title: "題名",
    details: "お問い合わせの詳細",
    check: "入力内容をご確認のうえ、チェックを入れてください",
    send: "送信",
    placeholder: {
      input: "Please input",
      fill: "お問い合わせの詳細を記入してください"
    }
  }
};
export default moduleJPLanguage;
