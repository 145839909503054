// import { createApp } from 'vue'
// import App from './App.vue'
// import ElementPlus from 'element-plus'
// import router from './router'
// import 'element-plus/dist/index.css'
// // import installElementPlus from './plugins/element' 

// const app = createApp(App)
// app.use(router)
// app.use(ElementPlus)
// app.mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'  
import router from './router/index.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from "./i18n/index";

import 'aos/dist/aos.css'
import './assets/css/base.css'

const app = createApp(App)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(router) 
app.use(pinia);
app.use(i18n)
app.provide('$axios', axios)
app.mount('#app')